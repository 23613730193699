<template>
  <div class="page-content">
    <div class="card row">
      <div class="card-body col-md-12" v-loading="loading.table">
        <div class="row align-items-center my-2">
          <div class="col-3 col-sm-3">
            <div class="card card-list border-primary p-1 mt-2 cursor-pointer" @click="getByStatus('won')">
              <div class="card-list-body p-2">
                <h6 class="text-center">Total Won</h6>
                <h5 class="text-center">{{ total.leads.won }} Leads</h5>
              </div>
            </div>
          </div>
          <div class="col-3 col-sm-3">
            <div class="card card-list border-primary p-1 mt-2 cursor-pointer" @click="getByStatus('won')">
              <div class="card-list-body p-2">
                <h6 class="text-center">Total Amount Won</h6>
                <h5 class="text-center">Rp. {{ total.amount.won }}</h5>
              </div>
            </div>
          </div>
          <div class="col-3 col-sm-3">
            <div class="card card-list border-danger p-1 mt-2 cursor-pointer" @click="getByStatus('lost')">
              <div class="card-list-body p-2">
                <h6 class="text-center">Total Lost</h6>
                <h5 class="text-center">{{ total.leads.lost }} Leads</h5>
              </div>
            </div>
          </div>
          <div class="col-3 col-sm-3">
            <div class="card card-list border-danger p-1 mt-2 cursor-pointer" @click="getByStatus('won')">
              <div class="card-list-body p-2">
                <h6 class="text-center">Total Amount Lost</h6>
                <h5 class="text-center">Rp. {{ total.amount.lost }}</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="row align-items-center mt-4">
          <div class="col-6 col-sm-6">
            <div class="row">
              <div class="col-3 col-sm-3">
                <el-select v-model="search.filter_type" placeholder="Select filter type" style="width:100%">
                  <el-option label="Lead" value="lead"></el-option>
                  <el-option label="Contact" value="contact"></el-option>
                  <el-option label="Assignee" value="user"></el-option>
                </el-select>
              </div>
              <div class="col-9 col-sm-9">
                <form class="search-form" @submit.prevent="doSearch()">
                  <div class="input-group border rounded-sm">
                    <div class="input-group-prepend">
                      <div class="input-group-text border-0 rounded-sm">
                        <FeatherIcon type="search" class="icon-md cursor-pointer" />
                      </div>
                    </div>
                    <input v-model="search.search" type="text" class="form-control  border-0 rounded-sm" id="searchForm" placeholder="Search here...">
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="col-3 col-sm-3">
            <el-select @change="doSearch()" v-model="selected_min_amount" placeholder="Select range amount" style="width:100%">
              <el-option
                v-for="(item) in range_amount"
                :key="item.min"
                :label="item.label"
                :value="item.min"
              >
              </el-option>
            </el-select>
          </div>
          <div class="col-3 col-sm-3">
            <el-select @change="doSearch()" v-model="search.reason" placeholder="Select lost reason" style="width:100%">
              <el-option
                v-for="item in rejected_reason"
                :key="item"
                :label="item"
                :value="item">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-12 col-sm-12 d-flex justify-content-end">
            <el-date-picker
              v-model="search.date_range"
              type="daterange"
              size="small"
              style="width: 442px"
              :picker-options="date_picker_options"
              @change="doSearch()"
              range-separator="To"
              start-placeholder="Start date"
              end-placeholder="End date">
            </el-date-picker>
            <el-button :loading="loading.report" @click="downloadReport();" size="small" class="btn-primary ml-2" icon="el-icon-download">Download</el-button>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-12 col-sm-12">
            <div class="table-responsive">
              <table class="table table-hover table-ordered">
                <thead>
                  <tr>
                    <th>
                      <div class="d-flex align-items-center">
                        <span>Lead name</span>
                      </div>
                    </th>
                    <th>
                      <div class="d-flex align-items-center">
                        <span>Assignee</span>
                      </div>
                    </th>
                    <th>
                      <div class="d-flex align-items-center">
                        <span>Contact</span>
                      </div>
                    </th>
                    <th>
                      <div class="d-flex align-items-center">
                        <span>Projection Amount</span>
                      </div>
                    </th>
                    <th>
                      <div class="d-flex align-items-center">
                        <span>Due Date</span>
                      </div>
                    </th>
                    <th>
                      <div class="d-flex align-items-center">
                        <span>Complete Date</span>
                      </div>
                    </th>
                    <th>
                      <div class="text-center align-items-center">
                        <span>Status</span>
                      </div>
                    </th>
                    <th>
                      <div class="text-center align-items-center">
                        <span>Reason</span>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="cursor-pointer" v-for="item in leadList" :key="item.id" @click="detailLead(item)">
                    <td>{{ item.name }}</td>
                    <td>{{ item.user?.name }}</td>
                    <td>{{ item.contact.display_name || item.contact.name }}<br>
                    <span class="text-muted tx-12">{{ item.contact.phone_number }}</span>
                    </td>
                    <td>{{ item.amount ? parseInt(item.amount).toLocaleString() : '' }}</td>
                    <td>{{ item.due_date_str }}</td>
                    <td>{{ item.completed_at_str }}</td>
                    <td class="text-center">
                      <b-badge :variant="item.status=='won'?'primary':'danger'" class="p-1">{{ item.status_str }}</b-badge>
                    </td>
                    <td>{{ item.status == 'lost' ? item.reason : '-' }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <el-empty v-if="totalRow == 0" description="No data"></el-empty>
        <b-pagination v-if="totalRow > search.per_page" v-model="currentPage" :total-rows="totalRow" :per-page="search.per_page"/>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import popupErrorMessages from '@/library/popup-error-messages';
import leadsAPI from '../../../api/leads';

export default {
  name: 'LeadsHistory',
  metaInfo: {
    title: 'Leads Completed',
  },
  data() {
    return {
      loading: {
        table: false,
        report: false,
      },
      leadList: [],
      total: {
        amount: {
          won: 0,
          lost: 0,
        },
        leads: {
          won: 0,
          lost: 0,
        },
      },
      search: {
        page: this.$route.query.page || 1,
        date_range: [
          this.$route.query.start_date || new Date(),
          this.$route.query.end_date || new Date(),
        ],
        filter_type: this.$route.query.filter_type || 'lead',
        search: this.$route.query.search || '',
        status: this.$route.query.status || '',
        min_amount: this.$route.query.min_amount || '',
        max_amount: this.$route.query.max_amount || '',
        reason: this.$route.query.reason || '',
        per_page: 20,
      },
      selected_min_amount: '',
      page: 1,
      totalRow: 0,
      rejected_reason: [
        'Insufficient budget',
        'Product does not fit need',
        'Not satisfied with conditions',
        'Bought from competitor',
        'Others',
      ],
      range_amount: [
        {
          label: 'Less than equal 1.000.000',
          min: 0,
          max: 1000000,
        },
        {
          label: '1.000.000 - 10.000.000',
          min: 1000000,
          max: 10000000,
        },
        {
          label: '10.000.000 - 100.000.000',
          min: 10000000,
          max: 100000000,
        },
        {
          label: 'More than 100.000.000',
          min: 100000000,
          max: 0,
        },
      ],
      date_picker_options: {
        disabledDate: (time) => {
          const today = moment();
          const diff = today.diff(moment(time), 'd');
          if (diff > 90 || diff < 0) {
            return true;
          }
          if (diff === 0) {
            if (today.isSame(moment(time), 'd') === false) {
              return true;
            }
          }
          return false;
        },
        shortcuts: [
          {
            text: 'This week',
            onClick(picker) {
              const start = moment().startOf('week').format();
              const end = moment().format();
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'This month',
            onClick(picker) {
              const start = moment().startOf('month').format();
              const end = moment().format();
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'Last week',
            onClick(picker) {
              const start = moment().subtract(1, 'week').startOf('week').format();
              const end = moment().subtract(1, 'week').endOf('week').format();
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'Last month',
            onClick(picker) {
              const start = moment().subtract(1, 'month').startOf('month').format();
              const end = moment().subtract(1, 'month').endOf('month').format();
              picker.$emit('pick', [start, end]);
            },
          },
        ],
      },
    };
  },
  computed: {
    currentPage: {
      get() {
        return this.search.page;
      },
      set(val) {
        this.search.page = val;
        this.getLeadList();
      },
    },
    activeWorkspace() {
      return this.$store.state.workspace.activeWorkspace;
    },
  },
  mounted() {
    this.getLeadList();
  },
  methods: {
    doSearch() {
      this.page = 1;
      this.getLeadList();
    },
    getByStatus(status) {
      this.page = 1;
      this.search.status = status;
      this.getLeadList();
    },
    detailLead(dt) {
      this.$router.push(`/leads/card/${dt.id}`);
    },
    getLeadList() {
      const options = this.search;
      if (this.selected_min_amount) {
        const amount = this.range_amount.find((v) => v.min === this.selected_min_amount);
        options.min_amount = amount.min;
        options.max_amount = amount.max;
      }
      const [start_date, end_date] = this.search.date_range;
      options.start_date = start_date;
      options.end_date = end_date;
      this.loading.table = true;
      leadsAPI.get_completed_leads(
        this.activeWorkspace._id,
        options,
      ).then(async (res) => {
        this.loading.table = false;
        await popupErrorMessages(res);
        this.totalRow = res.data.total;
        this.total.leads.won = res.data.count_status?.leads_won ? parseInt(res.data.count_status?.leads_won, 0).toLocaleString() : 0;
        this.total.leads.lost = res.data.count_status?.leads_lost ? parseInt(res.data.count_status?.leads_lost, 0).toLocaleString() : 0;
        this.total.amount.won = res.data.count_status?.amount_won ? parseInt(res.data.count_status?.amount_won, 0).toLocaleString() : 0;
        this.total.amount.lost = res.data.count_status?.amount_lost ? parseInt(res.data.count_status?.amount_lost, 0).toLocaleString() : 0;
        this.leadList = res.data.rows.map((lead) => {
          const data = lead;
          ['due_date', 'completed_at'].forEach((v) => {
            data[`${v}_str`] = lead[v] ? moment(lead[v]).format('DD MMM YYYY, HH:mm') : '-';
          });
          data.status_str = lead.status.toUpperCase();
          return data;
        });
      }).catch(async () => {
        this.loading.table = false;
      });
    },
    async downloadReport() {
      this.$confirm(this.$t('report.confirm_download'), this.$t('general.confirmation'), {
        confirmButtonText: this.$t('general.yes'),
        cancelButtonText: this.$t('general.no'),
        type: 'warning',
        center: true,
        /* eslint-disable no-param-reassign */
        beforeClose: async (action, instance, cb) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            this.loading.report = true;
            const options = this.search;
            if (this.selected_min_amount) {
              const amount = this.range_amount.find((v) => v.min === this.selected_min_amount);
              options.min_amount = amount.min;
              options.max_amount = amount.max;
            }
            const [start_date, end_date] = this.search.date_range;
            options.start_date = start_date;
            options.end_date = end_date;
            const response = await leadsAPI.download(
              this.activeWorkspace._id,
              options,
            ).catch(() => {});
            this.loading.report = false;
            instance.confirmButtonLoading = false;
            await popupErrorMessages(response);
            this.$message({
              title: this.$t('general.success'),
              type: 'success',
              message: this.$t('general.waiting_download'),
              duration: 30 * 1000,
              showClose: true,
            });
          }
          cb();
        },
      });
    },
  },
};
</script>
